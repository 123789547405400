import { useEffect, useRef } from 'react';
import { register } from 'swiper/element/bundle';
import { useTheme } from '@mui/material/styles';

export function Swiper({
  children,
  isPrimaryColor = true,
  alternativeColor = '#FFFFFF',
  navigationSize = '44px',
  ...rest
}) {
  const swiperRef = useRef(null);
  const theme = useTheme();
  const dropShadow =
    alternativeColor !== '#FFFFFF' ? 'none' : 'drop-shadow(2px 4px 2px #000)';

  useEffect(() => {
    register();

    const params = {
      ...rest,
      injectStyles: [
        `
          :host {
            --swiper-theme-color: ${
              isPrimaryColor ? theme.palette.primary.main : alternativeColor
            };
            --swiper-navigation-size: ${navigationSize}
          }

          .swiper-button-next svg,
          .swiper-button-prev svg {
            filter: ${isPrimaryColor ? 'none' : dropShadow};
          }
        `,
      ],
    };

    Object.assign(swiperRef.current, params);

    swiperRef.current.initialize();
  }, [
    alternativeColor,
    dropShadow,
    isPrimaryColor,
    navigationSize,
    rest,
    theme.palette.primary.main,
  ]);

  return (
    <swiper-container init="false" ref={swiperRef}>
      {children}
    </swiper-container>
  );
}

export function SwiperSlide({ children, ...rest }) {
  return <swiper-slide {...rest}>{children}</swiper-slide>;
}
